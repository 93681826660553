import RestService from '@/services/RestService'

let resourceName = 'heYiEngineeringListMonthPlan'

class HeYiEngineeringListMonthPlanService extends RestService {
}

const heYiEngineeringListMonthPlanService = new HeYiEngineeringListMonthPlanService(resourceName)

export default heYiEngineeringListMonthPlanService
