<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="heYiEngineeringListMonthPlanModel"
    label-width="120px"
  >
    <el-form-item label="形象进度清单：" prop="heYiEngineeringListGuid">
      <el-select v-model="heYiEngineeringListMonthPlanModel.heYiEngineeringListGuid" clearable>
        <el-option v-for="heYiEngineeringList of heYiEngineeringLists"
                   :key="heYiEngineeringList.heYiEngineeringListGuid"
                   :value="heYiEngineeringList.heYiEngineeringListGuid"
                   :label="heYiEngineeringList.optionName"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="年份：" prop="year">
      <el-input-number
        v-model="heYiEngineeringListMonthPlanModel.year"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="月份：" prop="year">
      <el-input-number
        v-model="heYiEngineeringListMonthPlanModel.month"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="数量：" prop="num">
      <el-input-number
        v-model="heYiEngineeringListMonthPlanModel.num"
        :step="0.01"
        :min="0"
        :precision="2"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="标段：" prop="tenderGuid">
      <el-select v-model="heYiEngineeringListMonthPlanModel.tenderGuid" clearable>
        <el-option v-for="tender of tenders" :key="tender.tenderGuid" :value="tender.tenderGuid"
                   :label="tender.tenderName"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import HeYiEngineeringListMonthPlanModel from '@/model/HeYiEngineeringListMonthPlanModel'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'
import heYiEngineeringListService from '@/services/heYiEngineeringListService'

export default {
  name: 'HeYiEngineeringListMonthPlanForm',
  props: {
    heYiEngineeringListMonthPlanModel: {
      type: HeYiEngineeringListMonthPlanModel,
      default () {
        return new HeYiEngineeringListMonthPlanModel()
      }
    }
  },
  data () {
    return {
      heYiEngineeringLists: [],
      tenders: [],
      rules: {
        engineeringName: [
          {
            required: true,
            message: '请输入形象进度名称',
            trigger: 'blur'
          }
        ],
        engineeringType: [
          {
            required: true,
            message: '请输入类型',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入形象进度编码',
            trigger: 'blur'
          }
        ],
        unit: [
          {
            required: true,
            message: '请输入单位',
            trigger: 'blur'
          }
        ],
        tenderGuid: [
          {
            required: true,
            message: '请选择标段',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          this.tenders = res.data.data
        })
    },
    getHeYiEngineeringLists () {
      heYiEngineeringListService.list({}, { tenderGuid: auth.getUserInfo().tenderGuid })
        .then(res => {
          this.heYiEngineeringLists = res.data.list.map(item => ({
            ...item,
            optionName: `${item.code}.  ${[item.engineeringName, item.parentType, item.engineeringType].filter(it => !!it).join(' - ')}`
          }))
        })
    }
  },
  mounted () {
    this.heYiEngineeringListMonthPlanModel.year = this.heYiEngineeringListMonthPlanModel.year || new Date().getFullYear()
    this.heYiEngineeringListMonthPlanModel.month = this.heYiEngineeringListMonthPlanModel.month || new Date().getMonth() + 1
    this.getTenders()
    this.getHeYiEngineeringLists()
  }
}
</script>
