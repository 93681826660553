import utility from '@/common/utility'
export default class HeYiEngineeringListYearPlanModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.heYiEngineeringListYearPlanGuid
      this.heYiEngineeringListYearPlanGuid = props.heYiEngineeringListYearPlanGuid
      this.year = props.year
      this.num = props.num
      this.tenderGuid = props.tenderGuid
      this.heYiEngineeringListGuid = props.heYiEngineeringListGuid
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.heYiEngineeringListYearPlanGuid) this.heYiEngineeringListYearPlanGuid = ''
    if (!this.year) this.year = 0
    if (!this.num) this.num = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.heYiEngineeringListGuid) this.heYiEngineeringListGuid = ''
  }
  generatePrimaryKey () {
    this.heYiEngineeringListYearPlanGuid = utility.getUuid()
    this.resourceId = this.heYiEngineeringListYearPlanGuid
  }
}
