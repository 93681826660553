import utility from '@/common/utility'
export default class HeYiEngineeringListMonthPlanModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.heYiEngineeringListMonthPlanGuid
      this.heYiEngineeringListMonthPlanGuid = props.heYiEngineeringListMonthPlanGuid
      this.year = props.year
      this.month = props.month
      this.num = props.num
      this.tenderGuid = props.tenderGuid
      this.heYiEngineeringListGuid = props.heYiEngineeringListGuid
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.heYiEngineeringListMonthPlanGuid) this.heYiEngineeringListMonthPlanGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
    if (!this.num) this.num = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.heYiEngineeringListGuid) this.heYiEngineeringListGuid = ''
  }
  generatePrimaryKey () {
    this.heYiEngineeringListMonthPlanGuid = utility.getUuid()
    this.resourceId = this.heYiEngineeringListMonthPlanGuid
  }
}
