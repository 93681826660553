<template>
  <div class="date-stat-page">
    <my-search-bar>
      <el-form inline>
        <el-form-item label="标段：" prop="tenderGuid">
          <el-select v-if="tenders.length" v-model="tenderGuid" clearable>
            <el-option v-for="tender of tenders" :key="tender.tenderGuid" :value="tender.tenderGuid"
                       :label="tender.tenderName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input-number v-model="year" :step="1" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-input-number v-model="month" :step="1" step-strictly :min="1" :max="12"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item v-if="canSave">
          <el-button @click="handleSave">保存</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handlePdfExport">PDF导出</el-button>
        </el-form-item>
        <el-form-item>
          当前数据：{{ displayText }}
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="excelContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import tenderService from '@/services/tenderService'

const fileSaver = require('file-saver')

export default {
  name: 'FinalG11',
  components: { MySearchBar },
  data () {
    return {
      guidMap: {},
      reverseGuidMap: {},
      displayText: `${new Date().getFullYear()}年${new Date().getMonth() + 1}月`,
      tenderGuid: '',
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      tenders: []
    }
  },
  computed: {
    canSave () {
      return this.tenderGuid === auth.getUserInfo().tenderGuid
    }
  },
  methods: {
    async handlePdfExport () {
      this.$loading({})
      const response = await axios.get(`${config.restHost}/heYiMeterageListDateStat/stat?tenderGuid=${this.tenderGuid}&year=${this.year}&month=${this.month}`, {
        responseType: 'blob'
      })
      const formData = new FormData()
      formData.append('file', response.data)
      axios.post('http://47.93.180.63:8779/apis/convert', formData, {
        responseType: 'blob'
      }).then(response => {
        this.$loading({}).close()
        fileSaver.saveAs(response.data, '报表.pdf')
      }).catch(error => {
        this.$loading({}).close()
        console.log(error)
      })
    },
    handleExport () {
      // const tenderName = this.tenders.find(tender => tender.tenderGuid === this.tenderGuid).tenderName
      // this.exportExcel(`${tenderName} ${this.year}年${this.month}月 清单日统计.xlsx`)
      const url = `${config.restHost}/heYiMeterageListDateStat/stat?tenderGuid=${this.tenderGuid}&year=${this.year}&month=${this.month}`
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.click()
    },
    exportExcel (fileName) {
      const excelIo = new GC.Spread.Excel.IO()
      const json = this.workbook.toJSON()
      excelIo.save(json, (blob) => {
        fileSaver.saveAs(blob, fileName)
      })
    },
    async handleSearch () {
      await this.init()
    },
    async handleSave () {
      this.$loading({})

      // 处理数据
      const sheet = this.workbook.getActiveSheet()

      const jsonData = []
      const rowCount = sheet.getRowCount()

      for (let rowIndex = 4; rowIndex <= rowCount; ++rowIndex) {
        for (let columnIndex = 5; columnIndex < 36; ++columnIndex) {
          const cellValue = sheet.getCell(rowIndex, columnIndex).value()
          if (cellValue) {
            const item = {
              year: this.year,
              month: this.month,
              date: columnIndex - 4,
              num: parseFloat(cellValue),
              tenderGuid: this.tenderGuid,
              // meterageListGuid: sheet.getCell(rowIndex, 41).value()
              meterageListGuid: this.reverseGuidMap[rowIndex],
              price: sheet.getCell(rowIndex, 3).value()
            }
            jsonData.push(item)
          }
        }
      }

      this.$confirm(`当前共有${this.displayText}数据${jsonData.length}条，确认要提交吗？`, '确认')
        .then(async () => {
          const response = await axios.post(`${config.restHost}/heYiMeterageListDateStat/batch/${this.tenderGuid}/${this.year}/${this.month}`, jsonData, {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`
            }
          })
          console.log(response.data)
          this.$message.success('操作成功')
          this.$loading({}).close()
          await this.init()
        }).catch(() => {
          this.$loading({}).close()
        })
    },
    async init () {
      this.$loading({})

      // 加载excel模版
      const excelTemplate = await axios.get('/excel/heyi-meterageList-template.xlsx', {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)
        this.bindStyles()
      })

      this.defaultLists = (await axios.get(`${config.restHost}/heYiMeterageListDateStat/defaultList`, {
        params: {
          tenderGuid: this.tenderGuid,
          year: this.year,
          month: this.month
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })).data

      this.defaultLists.forEach((item, index) => {
        this.guidMap[item.meterageListGuid] = index + 4
        this.reverseGuidMap[index + 4] = item.meterageListGuid
      })

      const array = this.defaultLists.map(defaultList => [
        defaultList.meterageCode,
        defaultList.meterageName,
        defaultList.unit,
        defaultList.price,
        defaultList.prevMonthNum
      ])

      const hiddenArray = this.defaultLists.map(defaultList => [
        defaultList.totalMonthNum,
        defaultList.meterageListGuid
      ])

      this.workbook.getActiveSheet().setRowCount(this.defaultLists.length + 4)
      this.workbook.getActiveSheet().setColumnCount(42)
      this.workbook.getActiveSheet().setArray(4, 0, array)
      this.workbook.getActiveSheet().setArray(4, 40, hiddenArray)
      // 设置剪贴板模式
      this.workbook.getActiveSheet().options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values

      this.dateStatList = (await axios.get(`${config.restHost}/heYiMeterageListDateStat`, {
        params: {
          tenderGuid: this.tenderGuid,
          year: this.year,
          month: this.month
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })).data.list

      const tempArray = new Array(this.defaultLists.length).fill(0).map(() => new Array(31).fill(''))
      console.log(tempArray, 'tonzoc')
      this.dateStatList.forEach(item => {
        const rowIndex = this.guidMap[item.meterageListGuid] - 4
        const cellIndex = item.date - 1
        if (cellIndex === 14) {
          console.log(rowIndex, cellIndex, item.num)
        }
        tempArray[rowIndex][cellIndex] = item.num
      })
      this.workbook.getActiveSheet().setArray(4, 5, tempArray)

      const tenderName = this.tenders.find(tender => tender.tenderGuid === this.tenderGuid).tenderName
      this.workbook.getActiveSheet().setValue(2, 5, `${tenderName}标段 ${this.year}年${this.month}月完成工程量`)
      this.displayText = `${tenderName}标段 ${this.year}年${this.month}月`

      const { data: projectConfig } = await axios.get(`${config.restHost}/projectConfigs/${auth.getUserInfo().projectGuid}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      if (projectConfig.dateStatProtection) {
        this.setProtection()
      }
      this.$loading({}).close()
    },
    async getTenders () {
      const response = await tenderService.listByUser(auth.getUserInfo().userGuid)
      console.log(response.data)
      this.tenders = response.data.data
    },
    bindStyles () {
      let invalidStyle = new GC.Spread.Sheets.Style()
      invalidStyle.name = 'invalidStyle'
      invalidStyle.borderLeft = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderRight = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderTop = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderBottom = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.backColor = 'red'
      invalidStyle.foreColor = 'white'

      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.name = 'unLockStyle'
      unLockStyle.borderLeft = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderRight = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderTop = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderBottom = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.backColor = 'green'
      unLockStyle.foreColor = 'white'
      unLockStyle.locked = false

      this.workbook.addNamedStyle(invalidStyle)
      this.workbook.addNamedStyle(unLockStyle)
    },
    setProtection () {
      this.workbook.getActiveSheet().options.isProtected = true
      const today = new Date().getDate()
      this.workbook.getActiveSheet().setStyleName(-1, today + 4, 'unLockStyle')
    }
  },
  async mounted () {
    await this.getTenders()
    if (this.tenders.length) {
      this.tenderGuid = this.tenders[0].tenderGuid
      this.$nextTick(async () => {
        await this.init()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.date-stat-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .spread-container {
    flex: 1;
  }
}
</style>
