import utility from '@/common/utility'
import auth from '@/common/auth'

export default class CompanyModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.companyGuid
      this.companyGuid = props.companyGuid
      this.companyName = props.companyName
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.companyGuid) this.companyGuid = ''
    if (!this.companyName) this.companyName = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.companyGuid = utility.getUuid()
    this.resourceId = this.companyGuid
  }
}
