import RestService from '@/services/RestService'

let resourceName = 'heYiEngineeringList'

class HeYiEngineeringListService extends RestService {
}

const heYiEngineeringListService = new HeYiEngineeringListService(resourceName)

export default heYiEngineeringListService
