<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="companyModel"
    label-width="70px"
    >
    <el-form-item label="名称：" prop="companyName">
      <el-input
        type="text"
        v-model="companyModel.companyName">
      </el-input>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="companyModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import CompanyModel from '@/model/CompanyModel'

export default {
  name: 'CompanyForm',
  props: {
    companyModel: {
      type: CompanyModel,
      default () {
        return new CompanyModel()
      }
    }
  },
  data () {
    return {
      rules: {
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  }
}
</script>
