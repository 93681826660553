<template>
  <div class="heYiEngineeringListYearPlan-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item label="标段：" prop="tenderGuid">
          <el-select v-model="searchModel.tenderGuid" clearable>
            <el-option v-for="tender of tenders" :key="tender.tenderGuid" :value="tender.tenderGuid"
                       :label="tender.tenderName"></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item>-->
        <!--          <el-input-->
        <!--            type="text"-->
        <!--            v-model="searchModel.heYiEngineeringListYearPlanName"-->
        <!--            placeholder="形象进度清单名称"-->
        <!--            clearable>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="heYiEngineeringListYearPlanList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
    >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="heYiEngineeringList.engineeringName" header-align="center" align="center"
                       label="工程名称"></el-table-column>
      <el-table-column prop="heYiEngineeringList.parentType" header-align="center" align="center"
                       label="父级类型"></el-table-column>
      <el-table-column prop="heYiEngineeringList.engineeringType" header-align="center" align="center"
                       label="类型"></el-table-column>
      <el-table-column prop="heYiEngineeringList.code" header-align="center" align="center"
                       label="编码"></el-table-column>
      <el-table-column prop="year" header-align="center" align="center"
                       label="年份"></el-table-column>
      <el-table-column prop="heYiEngineeringList.unit" header-align="center" align="center"
                       label="单位"></el-table-column>
      <el-table-column prop="num" header-align="center" align="center"
                       label="数量"></el-table-column>
      <el-table-column :formatter="tenderFormatter" prop="tenderGuid" header-align="center" align="center"
                       label="标段"></el-table-column>
      <el-table-column width="90" label="操作" header-align="center" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
               :title="formDialogTitle"
               size="md"
               ok-text="保存"
               cancel-text="取消"
               @ok-click="handleOkClick">
      <heYiEngineeringListYearPlan-form
        ref="heYiEngineeringListYearPlanForm"
        :heYiEngineeringListYearPlanModel="heYiEngineeringListYearPlanModel">
      </heYiEngineeringListYearPlan-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import HeYiEngineeringListYearPlanForm from '@/views/HeYiEngineeringListYearPlanManage/HeYiEngineeringListYearPlanForm'
import HeYiEngineeringListYearPlanModel from '@/model/HeYiEngineeringListYearPlanModel'
import heYiEngineeringListYearPlanService from '@/services/heYiEngineeringListYearPlanService'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import auth from '@/common/auth'
import tenderService from '@/services/tenderService'

export default {
  name: 'HeYiEngineeringListYearPlanIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MyElTable,
    MySearchBar,
    HeYiEngineeringListYearPlanForm
  },
  data () {
    return {
      total: 0,
      heYiEngineeringListYearPlanList: [],
      heYiEngineeringListYearPlanModel: new HeYiEngineeringListYearPlanModel(),
      formDialogTitle: '',
      tenders: [],
      searchModel: {
        tenderGuid: auth.getUserInfo().tenderGuid
      }
    }
  },
  methods: {
    tenderFormatter (row) {
      return this.tenders.find(item => item.tenderGuid === row.tenderGuid)?.tenderName
    },
    handleSearchClick () {
      this._getHeYiEngineeringListYearPlanList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加形象进度清单信息'
      this.heYiEngineeringListYearPlanModel = new HeYiEngineeringListYearPlanModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑形象进度清单信息'
      this.heYiEngineeringListYearPlanModel = new HeYiEngineeringListYearPlanModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _heYiEngineeringListYearPlanModel = new HeYiEngineeringListYearPlanModel(row)
          console.log(_heYiEngineeringListYearPlanModel)
          heYiEngineeringListYearPlanService.delete(_heYiEngineeringListYearPlanModel.resourceId)
            .then(res => {
              window.console.log(res)
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
              this._getHeYiEngineeringListYearPlanList()
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.heYiEngineeringListYearPlanForm.validate()(valid => {
        if (valid) {
          if (!this.heYiEngineeringListYearPlanModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getHeYiEngineeringListYearPlanList () {
      heYiEngineeringListYearPlanService.list(new PageInfo({
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        fieldOrder: 'heYiEngineeringListYearPlans.sortId asc'
      }), this.searchModel)
        .then(res => {
          this.total = res.data.total
          this.heYiEngineeringListYearPlanList = res.data.list
          this.$message({
            type: 'success',
            message: '列表加载成功！'
          })
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      heYiEngineeringListYearPlanService.add(this.heYiEngineeringListYearPlanModel)
        .then(res => {
          window.console.log(res)
          this.$refs.formDialog.close()
          this._getHeYiEngineeringListYearPlanList()
          this.$message({
            type: 'success',
            message: '添加成功！'
          })
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      heYiEngineeringListYearPlanService.edit(this.heYiEngineeringListYearPlanModel)
        .then(res => {
          window.console.log(res)
          this.$refs.formDialog.close()
          this._getHeYiEngineeringListYearPlanList()
          this.$message({
            type: 'success',
            message: '修改成功！'
          })
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    },
    getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          this.tenders = res.data.data
        })
    }
  },
  created () {
    this._getHeYiEngineeringListYearPlanList()
    this.getTenders()
    this.refreshMethod = this._getHeYiEngineeringListYearPlanList
  }
}
</script>
