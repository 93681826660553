import utility from '@/common/utility'
export default class HeYiEngineeringListModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.heYiEngineeringListGuid
      this.heYiEngineeringListGuid = props.heYiEngineeringListGuid
      this.engineeringName = props.engineeringName
      this.parentType = props.parentType
      this.engineeringType = props.engineeringType
      this.code = props.code
      this.unit = props.unit
      this.num = props.num
      this.tenderGuid = props.tenderGuid
      this.sortId = props.sortId
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.heYiEngineeringListGuid) this.heYiEngineeringListGuid = ''
    if (!this.engineeringName) this.engineeringName = ''
    if (!this.parentType) this.parentType = ''
    if (!this.engineeringType) this.engineeringType = ''
    if (!this.code) this.code = ''
    if (!this.unit) this.unit = ''
    if (!this.num) this.num = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.sortId) this.sortId = 0
  }
  generatePrimaryKey () {
    this.heYiEngineeringListGuid = utility.getUuid()
    this.resourceId = this.heYiEngineeringListGuid
  }
}
