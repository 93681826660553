<template>
  <div class="date-stat-page">
    <my-search-bar>
      <el-form inline>
        <el-form-item label="标段：" prop="tenderGuid">
          <el-select v-if="tenders.length" v-model="tenderGuid" clearable>
            <el-option v-for="tender of tenders" :key="tender.tenderGuid" :value="tender.tenderGuid"
                       :label="tender.tenderName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input-number v-model="year" :step="1" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-input-number v-model="month" :step="1" step-strictly :min="1" :max="12"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handlePdfExport">PDF导出</el-button>
        </el-form-item>
        <el-form-item>
          当前数据：{{ displayText }}
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="excelContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import tenderService from '@/services/tenderService'

const fileSaver = require('file-saver')

export default {
  name: 'FinalG11',
  components: { MySearchBar },
  data () {
    return {
      guidMap: {},
      reverseGuidMap: {},
      displayText: `${new Date().getFullYear()}年${new Date().getMonth() + 1}月`,
      tenderGuid: '',
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      tenders: []
    }
  },
  computed: {
    canSave () {
      return this.tenderGuid === auth.getUserInfo().tenderGuid
    }
  },
  methods: {
    handlePdfExport () {
      this.$loading({})
      const excelIo = new GC.Spread.Excel.IO()
      const json = this.workbook.toJSON()
      excelIo.save(json, (blob) => {
        const formData = new FormData()
        formData.append('file', blob)
        axios.post('http://47.93.180.63:8779/apis/convert', formData, {
          responseType: 'blob'
        }).then(response => {
          this.$loading({}).close()
          fileSaver.saveAs(response.data, '报表.pdf')
        }).catch(error => {
          this.$loading({}).close()
          console.log(error)
        })
      })
    },
    handleExport () {
      const tenderName = this.tenders.find(tender => tender.tenderGuid === this.tenderGuid).tenderName
      this.exportExcel(`${tenderName} ${this.year}年${this.month}月 形象进度日统计汇总.xlsx`)
    },
    exportExcel (fileName) {
      const excelIo = new GC.Spread.Excel.IO()
      const json = this.workbook.toJSON()
      excelIo.save(json, (blob) => {
        fileSaver.saveAs(blob, fileName)
      })
    },
    async handleSearch () {
      await this.init()
    },
    async init () {
      this.$loading({})

      // 加载excel模版
      const excelTemplate = await axios.get(`${config.restHost}/heYiMeterageListDateStat/summaryStat?tenderGuid=${this.tenderGuid}&year=${this.year}&month=${this.month}`, {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)
      })
      this.$loading({}).close()
    },
    async getTenders () {
      const response = await tenderService.listByUser(auth.getUserInfo().userGuid)
      console.log(response.data)
      this.tenders = response.data.data
    }
  },
  async mounted () {
    await this.getTenders()
    if (this.tenders.length) {
      this.tenderGuid = this.tenders[0].tenderGuid
      this.$nextTick(async () => {
        await this.init()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.date-stat-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .spread-container {
    flex: 1;
  }
}
</style>
