import RestService from '@/services/RestService'

let resourceName = 'heYiEngineeringListYearPlan'

class HeYiEngineeringListYearPlanService extends RestService {
}

const heYiEngineeringListYearPlanService = new HeYiEngineeringListYearPlanService(resourceName)

export default heYiEngineeringListYearPlanService
