<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="heYiEngineeringListModel"
    label-width="100px"
  >
    <el-form-item label="工程名称：" prop="engineeringName">
      <el-input
        type="text"
        v-model="heYiEngineeringListModel.engineeringName">
      </el-input>
    </el-form-item>
    <el-form-item label="父级类型：" prop="parentType">
      <el-input
        type="text"
        v-model="heYiEngineeringListModel.parentType">
      </el-input>
    </el-form-item>
    <el-form-item label="类型：" prop="engineeringType">
      <el-input
        type="text"
        v-model="heYiEngineeringListModel.engineeringType">
      </el-input>
    </el-form-item>
    <el-form-item label="编码：" prop="code">
      <el-input
        type="text"
        v-model="heYiEngineeringListModel.code">
      </el-input>
    </el-form-item>
    <el-form-item label="单位：" prop="unit">
      <el-input
        type="text"
        v-model="heYiEngineeringListModel.unit">
      </el-input>
    </el-form-item>
    <el-form-item label="数量：" prop="num">
      <el-input-number
        v-model="heYiEngineeringListModel.num"
        :step="0.01"
        :min="0"
        :precision="2"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="标段：" prop="tenderGuid">
      <el-select v-model="heYiEngineeringListModel.tenderGuid" clearable>
        <el-option v-for="tender of tenders" :key="tender.tenderGuid" :value="tender.tenderGuid"
                   :label="tender.tenderName"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="heYiEngineeringListModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import HeYiEngineeringListModel from '@/model/HeYiEngineeringListModel'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'

export default {
  name: 'HeYiEngineeringListForm',
  props: {
    heYiEngineeringListModel: {
      type: HeYiEngineeringListModel,
      default () {
        return new HeYiEngineeringListModel()
      }
    }
  },
  data () {
    return {
      tenders: [],
      rules: {
        engineeringName: [
          {
            required: true,
            message: '请输入形象进度名称',
            trigger: 'blur'
          }
        ],
        engineeringType: [
          {
            required: true,
            message: '请输入类型',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入形象进度编码',
            trigger: 'blur'
          }
        ],
        unit: [
          {
            required: true,
            message: '请输入单位',
            trigger: 'blur'
          }
        ],
        tenderGuid: [
          {
            required: true,
            message: '请选择标段',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          this.tenders = res.data.data
        })
    }
  },
  mounted () {
    this.getTenders()
  }
}
</script>
